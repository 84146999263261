const coursemap = angular.module("coursemap")

function navbarCtrl(sessionService) {
  // Bindings: user
  const vm = this
  vm.courses = "This is the first course"

  vm.logout = () => {
    sessionService.logout()
  }
}

navbarCtrl.$inject = ["sessionService"]

coursemap.controller("navbarCtrl", navbarCtrl)
