const coursemap = angular.module("coursemap")

function importCoursesCtrl(Restangular, courseService) {
  // Bindings: user, courses
  const vm = this
  vm.selectedThinkificCourseIds = []
  vm.allThinkificCourses = []
  vm.loadingIndicatorForList = false
  vm.loadingIndicatorForImport = false

  vm.$onInit = () => {
    vm.indicateStepTwo = vm.user.site_views <= 3 || vm.courses.length == 0
    vm.getAllCoursesFromThinkific()
  }

  vm.getAllCoursesFromThinkific = () => {
    vm.loadingIndicatorForList = true

    Restangular.all("thinkific_courses")
      .getList()
      .then(response => {
        vm.loadingIndicatorForList = false
        vm.allThinkificCourses = response.plain()
      })
  }

  vm.selectCourseForImport = (selectedCourseId, tokenId) => {
    vm.selectedThinkificCourseIds.push({
      id: selectedCourseId,
      token_id: tokenId
    })

    const index = vm.allThinkificCourses.findIndex(
      course => course.id === selectedCourseId
    )

    vm.allThinkificCourses[index]["selected"] = true
  }

  vm.removeCourseToImport = unselectedCourseId => {
    const indexInSelected = vm.selectedThinkificCourseIds.findIndex(
      course => course.id === unselectedCourseId
    )
    vm.selectedThinkificCourseIds.splice(indexInSelected, 1)

    const index = vm.allThinkificCourses.findIndex(
      course => course.id === unselectedCourseId
    )
    vm.allThinkificCourses[index]["selected"] = false
  }

  vm.importFullCourses = () => {
    vm.loadingIndicatorForImport = true

    const course_params = {
      thinkific_course: vm.selectedThinkificCourseIds
    }

    Restangular.one("thinkific_courses")
      .customPOST(course_params, undefined, undefined, {
        ContentType: "application/json"
      })
      .then(courses => {
        // Updates the courses view with the returned
        // courses through the courseService
        courseService.setUserCourses(courses.plain())
        vm.loadingIndicatorForImport = false
      })
  }
}

importCoursesCtrl.$inject = ["Restangular", "courseService"]

coursemap.controller("importCoursesCtrl", importCoursesCtrl)
