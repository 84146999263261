const coursemap = angular.module("coursemap")

function loginCtrl(sessionService) {
  const vm = this
  vm.emailRegex = /.+\@.+\..+/
  vm.pwd = ""
  vm.email = ""

  const redirectIfUserIsLoggedIn = (() => {
    sessionService.redirectIfAuthenticated("courses")
  })()

  vm.submit = () => {
    if (vm.email && vm.pwd) {
      sessionService.login(vm.email, vm.pwd)
    }
  }
}

loginCtrl.$inject = ["sessionService"]

coursemap.controller("loginCtrl", loginCtrl)
