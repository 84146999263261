const coursemap = angular.module("coursemap")

coursemap.factory("courseService", [
  "Restangular",
  "$state",
  "sessionService",
  "$window",
  "$cookies",
  (Restangular, $state, sessionService, $window, $cookies) => {
    const obj = {}
    let _userCourses = []
    let _currentCourseId = null

    obj.setUserCourses = userCourses => {
      _userCourses.length = 0
      _userCourses.push(...userCourses)
    }

    obj.getUserCourses = () => {
      return _userCourses == null ? "Please add a course" : _userCourses
    }

    obj.setCurrentCourseId = courseId => {
      _currentCourseId = courseId
      $cookies.put("currentCourseId", courseId)
    }

    obj.getCurrentCourseId = () => {
      _currentCourseId = _currentCourseId || $cookies.get("currentCourseId")
      return _currentCourseId
    }

    obj.getCoursesFromServer = () => {
      return Restangular.all("courses")
        .getList()
        .then(courses => {
          courses = courses.plain()
          obj.setUserCourses(courses)
          return _userCourses
        })
    }

    obj.getPublishedCourses = public_id => {
      return Restangular.all("courses")
        .customGETLIST("", { token: public_id })
        .then(courses => {
          return courses.plain()
        })
    }

    obj.getPublishedCourse = course_public_id => {
      return Restangular.all("courses")
        .customGETLIST("", { course_public_id: course_public_id })
        .then(courses => {
          return courses.plain()
        })
    }

    obj.showCourse = courseId => {
      return Restangular.one("courses", courseId)
        .get()
        .then(courseDetails => {
          const response = courseDetails.plain()
          if (_userCourses) {
            _updateCourseInList(response)
          }
          return response
        })
    }

    obj.createCourse = () => {
      const new_course = {
        course: {
          title: "New course map",
          status: "created"
        }
      }

      return Restangular.one("courses")
        .post("", new_course)
        .then(response => {
          $state.go("course", { courseId: response.id })
          _userCourses.unshift(response.plain())
          return "course-created"
        })
        .catch(response => {
          return "error"
        })
    }

    obj.delete = courseId => {
      return Restangular.one("courses", courseId)
        .remove()
        .then(response => {
          _removeCourseFromList(response)
          $state.go("courses")
          return "course-deleted"
        })
        .catch(response => {
          return "error"
        })
    }

    obj.update = (course, fieldToUpdate, onError) => {
      const course_updates = _courseFieldsToUpdate(course, fieldToUpdate)

      return Restangular.one("courses", course.id)
        .customPUT(course_updates, undefined, undefined, {
          ContentType: "application/json"
        })
        .then(response => {
          if (_userCourses) {
            _updateCourseInList(response.plain())
          }

          return fieldToUpdate == "refresh" ? response.plain() : "update-saved"
        })
        .catch(response => {
          return response.data.message
        })
    }

    const _courseFieldsToUpdate = (course, fieldToUpdate) => {
      switch (fieldToUpdate) {
        case "title":
          return { course: { title: course.title } }
        case "description":
          return { course: { description: course.description } }
        // case "instructor":
        //   return { course: { instructor: course.instructor } }
        // case "institute":
        //   return { course: { institute: course.institute } }
        case "status":
          return { course: { status: course.status } }
        case "locked":
          return { course: { locked: course.locked } }
        case "active":
          return { course: { active: course.active } }
        case "refresh":
          return { course: { refresh: true } }
        default:
          return { course: course }
      }
    }

    const _updateCourseInList = courseToUpdate => {
      for (let count = 0; count < _userCourses.length; count++) {
        if (_userCourses[count].id === courseToUpdate.id) {
          _userCourses[count] = courseToUpdate
          return
        }
      }
    }

    const _removeCourseFromList = courseToRemove => {
      for (let count = 0; count < _userCourses.length; count++) {
        if (_userCourses[count].id === courseToRemove.id) {
          _userCourses.splice(count, 1)
          return
        }
      }
    }

    return obj
  }
])
