const coursemap = angular.module("coursemap")

function studentCtrl($state) {
  // Bindings: user
  const vm = this
  vm.state = $state
}

studentCtrl.$inject = ["$state"]

coursemap.controller("studentCtrl", studentCtrl)
