const coursemap = angular.module("coursemap")

function studentCalendarCtrl(Restangular, $window, $state) {
  // Bindings: user
  const vm = this

  vm.answers = {}

  vm.error = { msg: "" }

  vm.timePreference = [
    "Early mornings (5am to 8am)",
    "Mornings (8am to 12pm)",
    "Afternoons (12pm to 4pm)",
    "Evenings (4pm to 7pm)",
    "Nights (7pm to 10pm)",
    "Late nights (10pm to 1am)",
    "Weekends",
    "Weekdays"
  ]

  vm.startDays = [
    "Today",
    "Tomorrow",
    "Saturday",
    "Sunday",
    "Friday",
    "Monday"
  ].filter(day => {
    return (
      day !== new Date().toLocaleString("en-us", { weekday: "long" }) &&
      day !==
        new Date(Date.now() + 1000 * 3600 * 24).toLocaleString("en-us", {
          weekday: "long"
        })
    )
  })

  vm.dailyHourOptions = [0.5, 1, 2, 3, 8]

  vm.syncWithGoogle = () => {
    if (!checkValidParams()) {
      $window.scrollTo(0, 0)
      return
    }

    const grant_params = {
      student: {
        course_id: vm.answers.selectedCourseId,
        email: vm.answers.email,
        daily_hours: vm.answers.dailyHours,
        start_day: vm.answers.startDay,
        early_morning:
          vm.answers.timePreference["Early mornings (5am to 8am)"] || "false",
        morning: vm.answers.timePreference["Mornings (8am to 12pm)"] || "false",
        afternoon:
          vm.answers.timePreference["Afternoons (12pm to 4pm)"] || "false",
        evening: vm.answers.timePreference["Evenings (4pm to 7pm)"] || "false",
        night: vm.answers.timePreference["Nights (7pm to 10pm)"] || "false",
        late_night:
          vm.answers.timePreference["Late nights (10pm to 1am)"] || "false",
        weekdays: vm.answers.timePreference["Weekends"] || "false",
        weekends: vm.answers.timePreference["Weekdays"] || "false"
      }
    }

    return Restangular.one("google_calendars")
      .one("grant")
      .customPOST(grant_params, undefined, undefined, {
        ContentType: "application/json"
      })
      .then(redirect_url => {
        $window.location.href = redirect_url
      })
  }

  vm.generateICSFile = () => {
    if (!checkValidParams()) {
      $window.scrollTo(0, 0)
      return
    }

    const grant_params = {
      student: {
        course_id: vm.answers.selectedCourseId,
        email: vm.answers.email,
        daily_hours: vm.answers.dailyHours,
        start_day: vm.answers.startDay,
        early_morning:
          vm.answers.timePreference["Early mornings (5am to 8am)"] || "false",
        morning: vm.answers.timePreference["Mornings (8am to 12pm)"] || "false",
        afternoon:
          vm.answers.timePreference["Afternoons (12pm to 4pm)"] || "false",
        evening: vm.answers.timePreference["Evenings (4pm to 7pm)"] || "false",
        night: vm.answers.timePreference["Nights (7pm to 10pm)"] || "false",
        late_night:
          vm.answers.timePreference["Late nights (10pm to 1am)"] || "false",
        weekdays: vm.answers.timePreference["Weekends"] || "false",
        weekends: vm.answers.timePreference["Weekdays"] || "false",
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }

    return Restangular.one("ics_calendars")
      .customPOST(grant_params, undefined, undefined, {
        ContentType: "text/html"
      })
      .then(response => {
        vm.error.msg = ""

        if (response.message == "job-created") {
          $state.go("student.setup-email-sent")
        } else {
          vm.error.message =
            "The email was not sent. Please try again or contact us if this persists."
        }
      })
  }

  const checkValidParams = () => {
    vm.error.msg = ""

    if (!vm.answers.selectedCourseId) {
      vm.error.msg = "Please select a course"
    } else if (!vm.answers.timePreference) {
      vm.error.msg =
        "Please select the time slot during which you prefer to study"
    } else if (!vm.answers.dailyHours) {
      vm.error.msg =
        "Please select the number of hours you aim to spend per day on this course"
    } else if (!vm.answers.startDay) {
      vm.error.msg = "Please select the day you want to start learning"
    } else if (!vm.answers.email) {
      vm.error.msg = "Please enter your email address"
    } else {
      return true
    }

    return false
  }
}

studentCalendarCtrl.$inject = ["Restangular", "$window", "$state"]

coursemap.controller("studentCalendarCtrl", studentCalendarCtrl)
