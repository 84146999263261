const coursemap = angular.module("coursemap")

function scriptsCtrl(Restangular) {
  // Bindings: settings
  const vm = this
  vm.msg = { error: "", success: "" }

  let previousSettings = {}

  vm.$onInit = () => {
    previousSettings = angular.copy(vm.settings)
  }

  vm.toggleStudentDashFooterScript = () => {
    const settingToUpdate = {
      setting: {
        thinkific_student_dash_script:
          vm.settings.thinkific_student_dash_script,
        thinkific_student_dash_theme: vm.settings.thinkific_student_dash_theme
      }
    }

    vm.msg.success = ""
    vm.msg.error = ""

    updateRequest(settingToUpdate)
  }

  vm.toggleStudentDashButtonScript = () => {
    const settingToUpdate = {
      setting: {
        thinkific_student_dash_button_script:
          vm.settings.thinkific_student_dash_button_script,
        thinkific_student_dash_button_theme:
          vm.settings.thinkific_student_dash_button_theme
      }
    }

    vm.msg.success = ""
    vm.msg.error = ""

    updateRequest(settingToUpdate)
  }

  const updateRequest = settingToUpdate => {
    return Restangular.one("setting")
      .customPUT(
        settingToUpdate,
        undefined,
        {},
        { ContentType: "application/json" }
      )
      .then(
        userSetting => {
          updatedSetting = Object.keys(settingToUpdate.setting)[0]
          previousSettings[updatedSetting] = userSetting[updatedSetting]

          vm.msg.success =
            "Your Thinkific website has been successfully updated."

          return "updated"
        },
        error => {
          failedSetting = Object.keys(settingToUpdate.setting)[0]
          vm.settings[failedSetting] = previousSettings[failedSetting]

          vm.msg.error =
            "Unable to process the change.  Please refresh the page and try again.  If this persists, email connect@beastnotes.com."
          return "update-failed"
        }
      )
  }
}

scriptsCtrl.$inject = ["Restangular"]

coursemap.controller("scriptsCtrl", scriptsCtrl)
