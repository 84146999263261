const coursemap = angular.module("coursemap")

function createLessonCtrl() {
  const vm = this
  vm.newChapter = { add: false }

  vm.addChapter = () => {
    vm.newChapter.add = true
    vm.lesson.chapterTitle = "New chapter"
  }

  vm.createNewChapter = () => {
    vm.createChapter({
      title: vm.newChapterTitle
    }).then(createdChapter => {
      if (createdChapter && createdChapter.id) {
        vm.newChapter.add = false
        vm.selectChapter({ chapter: createdChapter })
        vm.lesson.chapterTitle = createdChapter.title
      }
    })
  }

  vm.selectChapterFromDropdown = chapter => {
    vm.selectChapter({ chapter: chapter })
    vm.newChapter.add = false
  }
}

createLessonCtrl.$inject = []

coursemap.controller("createLessonCtrl", createLessonCtrl)
