const coursemap = angular.module("coursemap")

function billingCtrl(Restangular, $window) {
  // Bindings: subscription, user
  const vm = this
  vm.lastSaved = null
  vm.price = { estimate: null }
  vm.error = { usageLimitMsg: "" }

  vm.$onInit = () => {
    vm.calculatePrice()
  }

  vm.launchSubscription = async (newPlanName, quantity = 1) => {
    const subscription_updates = {
      subscription: {
        plan_name: newPlanName,
        switch: true,
        quantity: quantity
      }
    }

    const response = await updateSubscription(subscription_updates)
    $window.location.href = response.redirect_url
  }

  vm.manageSubscription = async () => {
    const subscription_updates = {
      subscription: {
        manage: true
      }
    }

    const response = await updateSubscription(subscription_updates)
    $window.location.href = response.redirect_url
  }

  vm.calculatePrice = () => {
    const fixedPrice = 14.99

    if (vm.subscription.monthly_use_limit <= 400) {
      vm.price.estimate = fixedPrice
    } else if (
      vm.subscription.monthly_use_limit > 400 &&
      vm.subscription.monthly_use_limit <= 2000
    ) {
      const variablePrice =
        Math.ceil((vm.subscription.monthly_use_limit - 400) / 200) * 6
      vm.price.estimate = fixedPrice + variablePrice
    } else {
      const variablePrice =
        48 + (vm.subscription.monthly_use_limit - 2000) * 0.03
      vm.price.estimate = fixedPrice + variablePrice
    }
  }

  vm.updateSubscription = () => {
    vm.calculatePrice()

    if (!vm.subscription.monthly_use_limit) {
      return
    } else if (vm.subscription.monthly_use_limit < 400) {
      vm.error.usageLimitMsg =
        "A use limit of 400 will maximize the fixed price of the Growth plan"
    } else {
      vm.error.usageLimitMsg = ""
    }

    const subscription_updates = {
      subscription: {
        monthly_use_limit: vm.subscription.monthly_use_limit,
        monthly_notification_threshold:
          vm.subscription.monthly_notification_threshold
      }
    }

    updateSubscription(subscription_updates).then(response => {
      vm.lastSaved = new Date().toLocaleTimeString()
    })
  }

  const updateSubscription = updates => {
    return Restangular.one("subscription").customPUT(
      updates,
      undefined,
      undefined,
      {
        ContentType: "application/json"
      }
    )
  }
}

billingCtrl.$inject = ["Restangular", "$window"]

coursemap.controller("billingCtrl", billingCtrl)
