const coursemap = angular.module("coursemap")

function studentSearchCtrl($state, Restangular) {
  const vm = this
  vm.courses = []
  vm.error = { msg: "" }
  vm.search = { term: "" }
  vm.selectedCourse = {}
  vm.state = $state

  vm.findCourses = () => {
    if (vm.search.term.trim()) {
      const query = {
        term: vm.search.term
      }

      Restangular.one("search")
        .one("courses")
        .customGET("", query)
        .then(response => {
          const coursesFound = response.plain()
          if (coursesFound.length == 0) {
            vm.error.msg = "No matching courses found"
          } else {
            vm.error.msg = ""
          }
          vm.courses = coursesFound
        })
    }
  }
}

studentSearchCtrl.$inject = ["$state", "Restangular"]

coursemap.controller("studentSearchCtrl", studentSearchCtrl)
