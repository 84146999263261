const coursemap = angular.module("coursemap")

coursemap.directive("autofocus", [
  "$timeout",
  $timeout => {
    return {
      restrict: "A",
      link: ($scope, $element) => {
        $timeout(() => {
          $element[0].focus()
        })
      }
    }
  }
])
