const coursemap = angular.module("coursemap")

function landingCtrl($scope, $state, sessionService) {
  const vm = this
  vm.state = $state

  const redirectIfUserIsLoggedIn = (() => {
    sessionService.redirectIfAuthenticated("courses")
  })()
}

landingCtrl.$inject = ["$scope", "$state", "sessionService"]

coursemap.controller("landingCtrl", landingCtrl)
