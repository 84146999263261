const coursemap = angular.module("coursemap", [
  "angular.filter",
  "ngCookies",
  "ng-sortable",
  "restangular",
  "ui.router"
])

coursemap.config([
  "RestangularProvider",
  RestangularProvider => {
    RestangularProvider.setBaseUrl("/api/v1")
    RestangularProvider.setRequestSuffix(".json")
    RestangularProvider.setDefaultHttpFields({
      "Content-Type": "application/json"
    })
  }
])

coursemap.config([
  "$stateProvider",
  "$urlRouterProvider",
  ($stateProvider, $urlRouterProvider) => {
    $urlRouterProvider.otherwise("/")

    const states = [
      {
        name: "landing",
        url: "/creators",
        component: "landing",
        resolve: {
          function() {
            window.scrollTo(0, 0)
          }
        }
      },
      {
        name: "landing.signup",
        url: "/signup",
        component: "signup"
      },
      {
        name: "landing.login",
        url: "/login",
        component: "login"
      },
      {
        name: "courses",
        url: "/courses",
        component: "courses",
        resolve: {
          user: [
            "sessionService",
            sessionService => {
              if (!sessionService.redirectIfUnauthenticated()) {
                return sessionService.loggedInUser()
              }
            }
          ],
          courses: [
            "courseService",
            "sessionService",
            (courseService, sessionService) => {
              if (!sessionService.redirectIfUnauthenticated()) {
                return courseService.getCoursesFromServer()
              }
            }
          ]
        }
      },
      {
        name: "course",
        url: "/course",
        component: "course",
        params: {
          courseId: {
            value: "",
            dynamic: true
          }
        },
        resolve: {
          course: [
            "courseService",
            "sessionService",
            "$transition$",
            (courseService, sessionService, $transition$) => {
              if (!sessionService.redirectIfUnauthenticated()) {
                const currentCourseId = $transition$.params().courseId

                if (currentCourseId) {
                  courseService.setCurrentCourseId(currentCourseId)
                  return courseService.showCourse(currentCourseId)
                } else {
                  const currentCourseIdFromCookie = courseService.getCurrentCourseId()
                  return courseService.showCourse(currentCourseIdFromCookie)
                }
              }
            }
          ]
        }
      },
      {
        name: "invite-link",
        url: "/invite-link",
        component: "inviteLink",
        resolve: {
          user: [
            "userService",
            "sessionService",
            (userService, sessionService) => {
              if (!sessionService.redirectIfUnauthenticated()) {
                return userService.generateOrGetPublicId()
              }
            }
          ]
        }
      },
      {
        name: "student",
        url: "/",
        component: "student"
      },
      {
        name: "student.calendar",
        url: "calendar?token",
        params: {
          map: null
        },
        component: "studentCalendar",
        resolve: {
          courses: [
            "$stateParams",
            "courseService",
            ($stateParams, courseService) => {
              if ($stateParams.token) {
                return courseService.getPublishedCourses($stateParams.token)
              } else if ($stateParams.map) {
                return courseService.getPublishedCourse($stateParams.map)
              }
            }
          ]
        }
      },
      {
        name: "student.setup-email-sent",
        url: "success",
        component: "studentEmailConfirmation"
      },
      {
        name: "scripts",
        url: "/scripts",
        component: "scripts",
        resolve: {
          settings: [
            "Restangular",
            "sessionService",
            (Restangular, sessionService) => {
              if (!sessionService.redirectIfUnauthenticated()) {
                return Restangular.one("setting")
                  .get()
                  .then(response => {
                    return response.plain()
                  })
              }
            }
          ]
        }
      },
      {
        name: "settings",
        url: "/settings",
        component: "settings"
      },
      {
        name: "settings.billing",
        url: "/billing",
        component: "billing",
        resolve: {
          subscription: [
            "Restangular",
            "sessionService",
            (Restangular, sessionService) => {
              if (!sessionService.redirectIfUnauthenticated()) {
                return Restangular.one("subscription")
                  .get()
                  .then(response => {
                    return response
                  })
              }
            }
          ],
          user: [
            "sessionService",
            sessionService => {
              if (!sessionService.redirectIfUnauthenticated()) {
                return sessionService.loggedInUser()
              }
            }
          ]
        }
      },
      {
        name: "contact",
        url: "/contact",
        templateUrl: "templates/contact_us.html",
        resolve: {
          function() {
            window.scrollTo(0, 0)
          }
        }
      },
      {
        name: "terms-of-service",
        url: "/terms-of-service",
        templateUrl: "templates/legal/terms_of_service.html",
        resolve: {
          function() {
            window.scrollTo(0, 0)
          }
        }
      },
      {
        name: "privacy-policy",
        url: "/privacy-policy",
        templateUrl: "templates/legal/privacy_policy.html",
        resolve: {
          function() {
            window.scrollTo(0, 0)
          }
        }
      },
      {
        name: "cookie-policy",
        url: "/cookie-policy",
        templateUrl: "templates/legal/cookie_policy.html",
        resolve: {
          function() {
            window.scrollTo(0, 0)
          }
        }
      },
      {
        name: "acceptable-use-policy",
        url: "/acceptable-use-policy",
        templateUrl: "templates/legal/acceptable_use_policy.html",
        resolve: {
          function() {
            window.scrollTo(0, 0)
          }
        }
      },
      {
        name: "do-not-sell",
        url: "/do-not-sell",
        templateUrl: "templates/legal/do_not_sell_policy.html",
        resolve: {
          function() {
            window.scrollTo(0, 0)
          }
        }
      },
      {
        name: "faq",
        url: "/faq",
        templateUrl: "templates/guide/faq.html",
        resolve: {
          function() {
            window.scrollTo(0, 0)
          }
        }
      }
    ]

    states.forEach(state => {
      $stateProvider.state(state)
    })
  }
])
