const coursemap = angular.module("coursemap")

coursemap.factory("chapterService", [
  "Restangular",
  Restangular => {
    obj = {}

    const _chapters = {}

    obj.create = chapterDetails => {
      const new_chapter = {
        chapter: {
          title: chapterDetails.title,
          course_id: chapterDetails.course_id
        }
      }

      return Restangular.one("chapters")
        .post("", new_chapter)
        .then(response => {
          const createdChapter = response.plain()

          _chapters[chapterDetails.course_id].unshift(createdChapter)
          return createdChapter
        })
    }

    obj.getForCourse = courseId => {
      return _chapters[courseId] || []
    }

    obj.getForCourseFromServer = courseId => {
      return Restangular.one("chapters")
        .customGET("", { course_id: courseId })
        .then(response => {
          chapters = response.plain()
          _chapters[courseId] = chapters
          return chapters
        })
    }

    return obj
  }
])
