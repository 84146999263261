angular.module("coursemap").component("createLesson", {
  templateUrl: "templates/dash/create_lesson.html",
  controller: "createLessonCtrl",
  controllerAs: "createLessonCtrl",
  bindings: {
    browserTimeZone: "<",
    chaptersInCourse: "<",
    lastSaved: "<",
    lesson: "=",
    closeLessonCreator: "&",
    createChapter: "&",
    createLesson: "&",
    selectChapter: "&"
  }
})
