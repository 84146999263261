angular.module("coursemap").component("editLesson", {
  templateUrl: "templates/dash/edit_lesson.html",
  bindings: {
    browserTimeZone: "<",
    lastSaved: "<",
    minDateTime: "<",
    lesson: "=",
    closeLessonEditor: "&",
    delete: "&",
    toggleOptionalAttendance: "&",
    update: "&"
  }
})
