// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "@popperjs/core/dist/umd/popper"
import "bootstrap/dist/js/bootstrap"
import "angular/angular"
import "angular-cookies/angular-cookies"
import "angular-sanitize/index"
import "angular-filter/dist/angular-filter"
import "restangular/dist/restangular"
import "@uirouter/angularjs/release/angular-ui-router"
import Sortable from "sortablejs/modular/sortable.complete.esm"
import "@alokpradhan/angular-legacy-sortablejs-maintained/angular-legacy-sortable"

import "./app"

import "../services/chapterService"
import "../services/courseService"
import "../services/lessonService"
import "../services/sessionService"
import "../services/userService"

import "../controllers/navbarCtrl.js"
import "../directives/navbarDirective.js"

import "../directives/autoFocusDirective.js"
import "../directives/footerDirective.js"

import "../controllers/billingCtrl"
import "../components/billing"

import "../controllers/coursesCtrl"
import "../components/courses"

import "../controllers/courseCtrl"
import "../components/course"

import "../controllers/inviteLinkCtrl"
import "../components/inviteLink"

import "../controllers/importCoursesCtrl"
import "../components/importCourses"

import "../controllers/landingCtrl"
import "../components/landing"

import "../controllers/loginCtrl"
import "../components/login"

import "../controllers/scriptsCtrl"
import "../components/scripts"

import "../controllers/signupCtrl"
import "../components/signup"

import "../controllers/studentCtrl"
import "../components/student"

import "../controllers/studentCalendarCtrl"
import "../components/studentCalendar"

import "../controllers/studentSearchCtrl"
import "../components/studentSearch"

import "../components/createLesson"
import "../controllers/createLessonCtrl"

import "../components/editLesson"
import "../components/resources"
import "../components/settings"
import "../components/statusBadge"
import "../components/studentDashScriptPreview"
import "../components/studentDashButtonScriptPreview"
import "../components/studentEmailConfirmation"

Rails.start()
ActiveStorage.start()
