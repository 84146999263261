const coursemap = angular.module("coursemap")

function coursesCtrl($state, courseService) {
  // Bindings: user, courses
  const vm = this

  vm.error = { msg: "" }

  vm.$onInit = () => {
    vm.openImportCourses = vm.user.site_views <= 2 || vm.courses.length == 0
  }

  vm.openCourse = courseId => {
    $state.go("course", { courseId: courseId })
  }

  vm.closeImportCourses = () => {
    vm.openImportCourses = false
  }

  vm.createNewCourse = () => {
    vm.error.msg = ""

    courseService.createCourse().then(response => {
      if (response == "error") {
        vm.error.msg = "The course map could not created. Please try again."
      }
    })
  }
}

coursesCtrl.$inject = ["$state", "courseService"]

coursemap.controller("coursesCtrl", coursesCtrl)
