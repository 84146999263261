const coursemap = angular.module("coursemap")

function courseCtrl(chapterService, courseService, lessonService, $filter) {
  // Bindings: course
  const vm = this

  let _maxWeeks = 1
  let _moveLessonToWeek = 1
  let _moveLessonFromWeek = 1

  vm.selectedLesson = {}
  vm.openLessonEditor = false
  vm.lastSaved = null
  vm.loadingIndicatorToRefreshCourse = false
  vm.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  vm.error = { msg: "" }

  vm.newLesson = {
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  vm.openLessonCreator = false
  vm.chaptersInCourse = []
  vm.minDateTime = new Date().toISOString().substring(0, 16)

  vm.sortableConfig = {
    animation: 150,
    chosenClass: "sortable-chosen",
    dataIdAttr: "data-id",
    handle: ".sort-handle",
    group: {
      name: "lessons",
      put: (from, to) => {
        // Drag and drop micro-interaction
        // inverts the from and to
        _moveLessonToWeek = from.el.parentElement.value
        _moveLessonFromWeek = to.el.parentElement.value
      }
    },
    onEnd: evt => {
      const lessons_array = evt.models
      const lesson_selected = evt.models[evt.oldIndex]
      const lesson_shifted_from = evt.oldIndex
      const lesson_shifted_to = evt.newIndex

      // console.log("_moveLessonFromWeek", _moveLessonFromWeek)
      // console.log("_moveLessonToWeek", _moveLessonToWeek)
      // console.log("lesson_shifted_from", lesson_shifted_from)
      // console.log("lesson_shifted_to", lesson_shifted_to)

      const lessons_impacted = evt.models.filter(
        // +1 as the index is one behind the sort_order
        lesson => {
          if (_moveLessonFromWeek == _moveLessonToWeek) {
            if (lesson_shifted_from < lesson_shifted_to) {
              return (
                lesson.sort_order >= lesson_shifted_from + 1 &&
                lesson.sort_order <= lesson_shifted_to + 1
              )
            } else if (lesson_shifted_from > lesson_shifted_to) {
              return (
                lesson.sort_order <= lesson_shifted_from + 1 &&
                lesson.sort_order >= lesson_shifted_to + 1
              )
            }
          } else if (_moveLessonFromWeek !== _moveLessonToWeek) {
            return (
              (lesson.week == _moveLessonFromWeek &&
                lesson.sort_order >= lesson_shifted_from + 1) ||
              (lesson.week == _moveLessonToWeek &&
                lesson.sort_order <= lesson_shifted_to + 1)
            )
          }
        }
      )

      // console.log("lessons_impacted: ", lessons_impacted)

      if (
        lesson_shifted_from !== lesson_shifted_to ||
        _moveLessonFromWeek !== _moveLessonToWeek
      ) {
        updateSortOrderForLessons(
          lessons_impacted,
          lesson_selected,
          lesson_shifted_to,
          lesson_shifted_from
        )
      }
    }
  }

  const updateSortOrderForLessons = async (
    lessons_impacted,
    lesson_selected,
    lesson_shifted_to,
    lesson_shifted_from
  ) => {
    for (let lesson of lessons_impacted) {
      if (_moveLessonFromWeek == _moveLessonToWeek) {
        if (lesson.id == lesson_selected.id) {
          lesson.sort_order = lesson_shifted_to + 1
        } else {
          // Need to add checks to ensure the sort order doesn't
          // have a 0 or that there are no duplicates
          if (
            lesson_shifted_to !== lesson_shifted_from &&
            lesson_shifted_to > lesson_shifted_from
          ) {
            if (lesson.sort_order > 0) {
              lesson.sort_order -= 1
            }
          } else {
            lesson.sort_order += 1
          }
        }
      } else if (_moveLessonFromWeek !== _moveLessonToWeek) {
        if (lesson.week == _moveLessonFromWeek) {
          if (lesson.id == lesson_selected.id) {
            lesson.sort_order = lesson_shifted_to + 1
            lesson.week = _moveLessonToWeek
          } else {
            if (lesson.sort_order > 0) {
              lesson.sort_order -= 1
            }
          }
        } else if (lesson.week == _moveLessonToWeek) {
          lesson.sort_order += 1
        }
      }

      await vm.updateLesson(lesson, "sort_order_and_week")
      _moveLessonFromWeek = 1
      _moveLessonToWeek = 1
    }
  }

  vm.addNewLesson = () => {
    vm.chaptersInCourse = chapterService.getForCourse(vm.course.id)

    if (vm.chaptersInCourse.length === 0) {
      chapterService.getForCourseFromServer(vm.course.id).then(chapters => {
        vm.chaptersInCourse = chapters
      })
    }

    vm.openLessonEditor = false
    vm.openLessonCreator = true
    window.scrollTo(0, 0)
  }

  vm.closeLessonCreator = () => {
    vm.openLessonCreator = false
  }

  vm.selectChapter = chapter => {
    vm.newLesson.chapterTitle = chapter.title
    vm.newLesson.chapterId = chapter.id
  }

  vm.createLesson = chapter_id => {
    lessonService
      .create({
        title: vm.newLesson.title,
        course_id: vm.course.id,
        chapter_id: vm.newLesson.chapterId,
        week: vm.newLesson.week,
        time_estimate_in_minutes: vm.newLesson.time_estimate_in_minutes,
        content_type: vm.newLesson.content_type,
        due_date: vm.newLesson.due_date,
        description: vm.newLesson.description,
        optional: vm.newLesson.optional,
        start_time: vm.newLesson.start_time,
        end_time: vm.newLesson.end_time,
        time_zone: vm.newLesson.time_zone
        // set sort order to be the last lesson in the week
      })
      .then(response => {
        if (response.id) {
          vm.course.lessons.push(response)
          vm.lastSaved = new Date().toLocaleTimeString()
        }
      })
  }

  vm.deleteLesson = lesson => {
    lessonService.delete(lesson.id).then(response => {
      if (response.id) {
        const index = vm.course.lessons.findIndex(
          lesson => lesson.id === response.id
        )
        vm.course.lessons.splice(index, 1)
        vm.openLessonEditor = false
      }
    })
  }

  vm.editLesson = lessonId => {
    if (lessonId === 0) {
      vm.selectedLesson = vm.newLesson
    } else {
      vm.selectedLesson = vm.course.lessons.find(
        lesson => lesson.id === lessonId
      )

      vm.selectedLesson.due_date = vm.selectedLesson.due_date
        ? new Date(vm.selectedLesson.due_date)
        : null
      vm.selectedLesson.start_time = vm.selectedLesson.start_time
        ? new Date(vm.selectedLesson.start_time)
        : null
      vm.selectedLesson.end_time = vm.selectedLesson.end_time
        ? new Date(vm.selectedLesson.end_time)
        : null
    }

    vm.openLessonCreator = false
    vm.openLessonEditor = true
    window.scrollTo(0, 0)
  }

  vm.closeLessonEditor = () => {
    vm.openLessonEditor = false
    vm.selectedLesson = {}
  }

  vm.toggleOptionalAttendance = () => {
    lessonService.update(vm.selectedLesson, "optional").then(response => {
      if (response === "update-saved") {
        vm.selectedLesson.optional = !vm.selectedLesson.optional
        vm.lastSaved = new Date().toLocaleTimeString()
      }
    })
  }

  vm.updateLesson = (lesson, fieldToUpdate) => {
    const requiredFields = [
      "title",
      "lesson_type",
      "time_estimate_in_minutes",
      "week"
    ]

    const dateTimeFields = ["due_date", "start_time", "end_time"]

    if (requiredFields.includes(fieldToUpdate) && !lesson[fieldToUpdate]) {
      return
    } else if (
      dateTimeFields.includes(fieldToUpdate) &&
      !lesson[fieldToUpdate] === undefined
    ) {
      // !lesson[fieldToUpdate] === null allows dateTimes to be deleted
      return
    } else {
      lessonService.update(lesson, fieldToUpdate).then(response => {
        if (response === "update-saved") {
          vm.lastSaved = new Date().toLocaleTimeString()

          if (
            fieldToUpdate == "due_date" &&
            vm.selectedLesson.id == lesson.id
          ) {
            vm.selectedLesson.start_time = null
            vm.selectedLesson.end_time = null
          } else if (
            fieldToUpdate == "start_time" ||
            (fieldToUpdate == "end_time" && vm.selectedLesson.id == lesson.id)
          ) {
            vm.selectedLesson.due_date = null
          }
        }
      })
    }
  }

  vm.updateCourse = latestStatus => {
    const courseToUpdate = {
      id: vm.course.id,
      status: latestStatus
    }

    courseService.update(courseToUpdate, "status").then(response => {
      if (response === "update-saved") {
        vm.course.status = latestStatus
        window.scrollTo(0, 0)
      }
    })
  }

  vm.updateCourseTitle = () => {
    const courseToUpdate = {
      id: vm.course.id,
      title: vm.course.title
    }

    courseService.update(courseToUpdate, "title")
    // .then(response => {
    //   if (response === "update-saved") {
    //     vm.course.status = latestStatus
    //   }
    // })
  }

  vm.refreshCourse = () => {
    const courseToUpdate = {
      id: vm.course.id
    }

    vm.loadingIndicatorToRefreshCourse = true

    courseService.update(courseToUpdate, "refresh").then(response => {
      if (response && response.id) {
        vm.closeLessonEditor()
        vm.course = response
        vm.loadingIndicatorToRefreshCourse = false
      }
    })
  }

  vm.toggleCourseLock = () => {
    const courseToUpdate = {
      id: vm.course.id,
      locked: !vm.course.locked
    }

    courseService.update(courseToUpdate, "locked").then(response => {
      if (response === "update-saved") {
        vm.course.locked = !vm.course.locked
      }
    })
  }

  vm.createChapter = newChapterTitle => {
    const chapterDetails = {
      title: newChapterTitle,
      course_id: vm.course.id
    }

    return chapterService.create(chapterDetails)
  }

  vm.deleteCourse = async () => {
    vm.error.msg = ""

    if (
      confirm(
        "Are you sure you want to delete this course map and all associated details, including chapters, lessons, and student usage data?"
      )
    ) {
      const response = await courseService.delete(vm.course.id)
      if (response == "error") {
        vm.error.msg = "This course could not be deleted. Please try again."
      }
    }
  }
}

courseCtrl.$inject = [
  "chapterService",
  "courseService",
  "lessonService",
  "$filter"
]

coursemap.controller("courseCtrl", courseCtrl)
