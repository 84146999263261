const coursemap = angular.module("coursemap")

coursemap.directive("footer", [
  () => {
    return {
      templateUrl: "templates/directives/footer.html",
      restrict: "A"
    }
  }
])
