const coursemap = angular.module("coursemap")

coursemap.factory("lessonService", [
  "Restangular",
  "$state",
  "sessionService",
  "$window",
  "$cookies",
  (Restangular, $state, sessionService, $window, $cookies) => {
    var obj = {}
    var _courseLessons = null
    // var _courseId = null // get this from the course service

    obj.setCourseLessons = function(courseLessons) {
      _courseLessons = courseLessons
    }

    obj.getCourseLessons = function() {
      return _courseLessons
    }

    obj.create = lessonDetails => {
      const new_lesson = {
        lesson: {
          title: lessonDetails.title,
          course_id: lessonDetails.course_id,
          chapter_id: lessonDetails.chapter_id,
          week: lessonDetails.week,
          time_estimate_in_minutes: lessonDetails.time_estimate_in_minutes,
          content_type: lessonDetails.content_type,
          due_date: lessonDetails.due_date,
          description: lessonDetails.description,
          optional: lessonDetails.optional,
          start_time: lessonDetails.start_time,
          end_time: lessonDetails.end_time,
          time_zone: lessonDetails.time_zone
        }
      }

      return Restangular.one("lessons")
        .post("", new_lesson)
        .then(response => {
          return response.id ? response.plain() : response
        })
    }

    obj.update = (lesson, fieldToUpdate) => {
      const lesson_updates = _lessonFieldsToUpdate(lesson, fieldToUpdate)

      return Restangular.one("lessons", lesson.id)
        .customPUT(lesson_updates, undefined, undefined, {
          ContentType: "application/json"
        })
        .then(response => {
          if (response.id) {
            return "update-saved"
          }
        })
    }

    const _lessonFieldsToUpdate = (lesson, fieldToUpdate) => {
      switch (fieldToUpdate) {
        case "title":
          return { lesson: { title: lesson.title } }
        case "description":
          return { lesson: { description: lesson.description } }
        case "sort_order_and_week":
          return {
            lesson: { sort_order: lesson.sort_order, week: lesson.week }
          }
        case "sort_order":
          return { lesson: { sort_order: lesson.sort_order } }
        case "week":
          return { lesson: { week: lesson.week } }
        case "due_date":
          if (lesson.due_date === null) {
            return {
              lesson: { due_date: lesson.due_date }
            }
          } else {
            return {
              lesson: {
                due_date: lesson.due_date,
                start_time: "",
                end_time: ""
              }
            }
          }

        case "time_estimate_in_minutes":
          return {
            lesson: {
              time_estimate_in_minutes: lesson.time_estimate_in_minutes
            }
          }
        case "optional":
          return { lesson: { optional: lesson.optional } }
        case "content_type":
          return { lesson: { content_type: lesson.content_type } }
        case "start_time":
          return {
            lesson: {
              start_time: lesson.start_time,
              due_date: "",
              time_zone: lesson.time_zone
            }
          }
        case "end_time":
          return {
            lesson: {
              end_time: lesson.end_time,
              due_date: "",
              time_zone: lesson.time_zone
            }
          }
        case "time_zone":
          return { lesson: { time_zone: lesson.time_zone } }
        default:
          return { lesson: lesson }
      }
    }

    obj.delete = lessonId => {
      return Restangular.one("lessons", lessonId)
        .remove()
        .then(response => {
          return response
        })
    }

    return obj
  }
])
