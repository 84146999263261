angular.module("coursemap").component("importCourses", {
  templateUrl: "templates/dash/import_courses.html",
  controller: "importCoursesCtrl",
  controllerAs: "importCoursesCtrl",
  bindings: {
    courses: "<",
    user: "<",
    closeImportCourses: "&"
  }
})
