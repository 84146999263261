const coursemap = angular.module("coursemap")

coursemap.factory("sessionService", [
  "Restangular",
  "$state",
  "$cookies",
  (Restangular, $state, $cookies) => {
    let _currentUser = {}
    obj = {}

    obj.error = { message: null }

    const _setCurrentUserDetails = user => {
      _currentUser.id = user.id
      _currentUser.email = user.email
      _currentUser.first_name = user.first_name
      _currentUser.primary_role = user.role
      _currentUser.created_at = user.created_at
      _currentUser.monthly_uses = user.monthly_uses
      _currentUser.site_views = user.site_views
    }

    const _setCurrentUserToken = token => {
      _currentUser.tokenOne = token
    }

    const _getUserDetailsFromCookie = () => {
      return $cookies.get("_token_one")
    }

    // Consider a service for errors
    const _errors = response => {
      switch (parseInt(response.status)) {
        case 401:
          obj.error.message =
            "The credentials you've entered are incorrect. Please try again."
          break
        case 404:
          obj.error.message = "Please enter a valid username and password."
          break
        case 422:
          for (const key in response.data) {
            if (
              key == "email" &&
              response.data[key] == "has already been taken"
            ) {
              obj.error.message =
                "This email has already been taken. Please Login."
            }
          }
          break
        case 500:
          if (response.data.match("PG::UniqueViolation") !== null) {
            obj.error.message =
              "Your account has been created, please Login instead."
          }
          break
        default:
          obj.error.message = null
      }
    }

    const _destroySession = () => {
      _currentUser = {}
      $cookies.remove("currentCourseId")
    }

    obj.login = (email, pwd) => {
      const login = {
        login: { email: email, password: pwd },
        offset: new Date().getTimezoneOffset()
      }

      Restangular.one("login")
        .customPOST(login, undefined, undefined, {
          ContentType: "application/json"
        })
        .then(
          user => {
            _setCurrentUserDetails(user.plain())
            $state.go("courses")
          },
          response => {
            _errors(response)
          }
        )
    }

    obj.loggedInUserDetails = () => {
      return _currentUser
    }

    // How do I access obj.getUserDetailsFromServer from
    // within the service
    const _getUserDetailsFromServer = () => {
      return Restangular.one("user")
        .get()
        .then(user => {
          _setCurrentUserDetails(user.plain())
          return "user-details-retrieved"
        })
    }

    // This needs to be refactored. Restangular returns a promise,
    // but without an API call, it returns a value
    // This is only fine in ui-router resolves
    obj.loggedInUser = () => {
      if (
        angular.equals(_currentUser, {}) ||
        _currentUser["email"] == undefined
      ) {
        const token = _getUserDetailsFromCookie() || {}
        _setCurrentUserToken(token)

        return _getUserDetailsFromServer().then(response => {
          if (response == "user-details-retrieved") {
            return _currentUser
          }
        })
      } else {
        return _currentUser
      }
    }

    obj.logout = () => {
      Restangular.one("logout")
        .customDELETE(undefined, undefined, { ContentType: "application/json" })
        .then(response => {
          _destroySession()
          $state.go("landing.login")
          if (response.status == 202) {
            return "Logged out successfully."
          }
        })
    }

    obj.redirectIfAuthenticated = newState => {
      const token = _getUserDetailsFromCookie()

      if (token !== undefined) {
        $state.go(newState)
        _setCurrentUserToken(token)
      }
    }

    obj.redirectIfUnauthenticated = () => {
      const token = _getUserDetailsFromCookie()

      if (!token) {
        $state.go("landing.login")
        _destroySession()
        return true
      } else {
      }
      return false
    }

    obj.register = (
      email,
      pwd,
      pwdConfirmation
      // recaptcha,
      // expireOnError,
    ) => {
      let user = {
        user: {
          email: email,
          password: pwd,
          password_confirmation: pwdConfirmation
          // recaptcha: recaptcha
        }
      }

      Restangular.one("users")
        .customPOST(user, undefined, undefined, {
          ContentType: "application/json"
        })
        .then(
          user => {
            const token = _getUserDetailsFromCookie() || {}
            _setCurrentUserToken(token)
            _setCurrentUserDetails(user)
            $state.go("courses")
          },
          response => {
            // expireOnError()
            _errors(response)
          }
        )
    }

    return obj
  }
])
