const coursemap = angular.module("coursemap")

coursemap.factory("userService", [
  "Restangular",
  "$state",
  Restangular => {
    obj = {}

    obj.generateOrGetPublicId = () => {
      return Restangular.one("user")
        .customGET("", { fetch_public_id: true })
        .then(user => {
          return user.plain()
        })
    }

    obj.update = (user, fieldToUpdate) => {
      const user_updates = _userFieldsToUpdate(user, fieldToUpdate)

      return Restangular.one("user")
        .customPUT(user_updates, undefined, undefined, {
          ContentType: "application/json"
        })
        .then(response => {
          if (response.id) {
            return response.plain()
          }
        })
    }

    const _userFieldsToUpdate = (user, fieldToUpdate) => {
      switch (fieldToUpdate) {
        case "public_id":
          return { user: { set_public_id: true } }
        case "active_public_id":
          return { user: { active_public_id: user.active_public_id } }
      }
    }

    return obj
  }
])
