const coursemap = angular.module("coursemap")

function inviteLinkCtrl(userService) {
  // Bindings: user { public_id, active_public_id }
  const vm = this

  vm.uriToShare = "https://www.coursemap.ai/#!/calendar?token="

  vm.disable = () => {
    userService
      .update({ active_public_id: false }, "active_public_id")
      .then(user => {
        if (!user.active_public_id) {
          vm.uriToShare = ""
          vm.user.public_id = user.public_id
          vm.user.active_public_id = user.active_public_id
        }
      })
  }

  vm.enable = () => {
    userService
      .update({ active_public_id: true }, "active_public_id")
      .then(user => {
        if (user.active_public_id) {
          vm.uriToShare = "https://www.coursemap.ai/#!/students?token="
          vm.user.public_id = user.public_id
          vm.user.active_public_id = user.active_public_id
        }
      })
  }

  vm.refresh = () => {
    if (
      confirm(
        "You will need to be disable and enable active Scripts so they can use the updated invite link."
      )
    ) {
      if (vm.user.active_public_id) {
        userService.update({}, "public_id").then(user => {
          vm.user.public_id = user.public_id
        })
      }
    }
  }

  vm.copyToClipboard = () => {
    let range = document.createRange()
    range.selectNode(document.getElementById("invite-link"))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand("copy")
    window.getSelection().removeAllRanges()
  }
}

inviteLinkCtrl.$inject = ["userService"]

coursemap.controller("inviteLinkCtrl", inviteLinkCtrl)
