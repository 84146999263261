const coursemap = angular.module("coursemap")

coursemap.directive("navbar", [
  () => {
    return {
      templateUrl: "templates/directives/navbar.html",
      restrict: "A",
      controller: "navbarCtrl",
      controllerAs: "navbarCtrl"
    }
  }
])
